/* src/App.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: auto; /* Ensure body can scroll */
}

canvas {
  display: block;
}

img {
  display: block;
  margin: 0 auto;
}
/* 
HEADER
.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}
*/