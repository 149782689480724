/* DisplayComponents.css */

/* Styles for ImageDisplay */
.image-display {
    max-width: 600px;
    max-height: 400px;
    height: auto;
    width: auto;
  }
  
  .image-display.fullscreen {
    max-width: 100%;
    max-height: 100vh;
  }
  
  /* Styles for ModelDisplay */
  .model-display {
    height: 400px !important;
    width: 100% !important;
  }
  