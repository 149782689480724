@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,500');

/* Scope all styles to the .not-found-page class */
.not-found-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/257418/andy-holmes-698828-unsplash.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Roboto Mono", "Liberation Mono", Consolas, monospace;
  color: rgba(255, 255, 255, 0.87);
}

.not-found-page .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.not-found-page .container,
.not-found-page .container > .row,
.not-found-page .container > .row > div {
  height: 100%;
}

.not-found-page #countUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.not-found-page .number {
  font-size: 4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.not-found-page .text {
  font-weight: 300;
  text-align: center;
}

/* Custom styles for the link within .not-found-page */
.not-found-page a {
  color: #ffcc00; /* Change this to your desired color */
  text-decoration: underline;
}

.not-found-page a:hover {
  color: #ffffff; /* Change this to your desired hover color */
}
